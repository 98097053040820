body {
    min-height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    filter: drop-shadow(0 0 10px white);
    overflow-x: hidden;
  }
  
  @function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }
  
  .snow {
    $total: 200;
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
  
    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: $random-x + $random-offset;
      $random-x-end-yoyo: $random-x + ($random-offset / 2);
      $random-yoyo-time: random_range(30000, 80000) / 100000;
      $random-yoyo-y: $random-yoyo-time * 100vh;
      $random-scale: random(10000) * 0.0001;
      $fall-duration: random_range(10, 30) * 1s;
      $fall-delay: random(30) * -1s;
  
      &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }
  
      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }
        
        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
  }
  


body {
    // background-color: #f6f6f6;
    padding: 0;
    margin: 0;
}

// .container {
//     margin-left: auto;
//     margin-right: auto;
//     width: 100%;
//     max-width: 100%;
//     min-height: 100vh;
//     background-color: #fff;
//     display: flex;
//     flex-direction: column;
//     -ms-flex-negative: 0;
//     overflow: auto;
    
//     // box-shadow:	-5px 5px 5px #eee, 5px 5px 5px #eee;
// }

.container {
    min-height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    -ms-flex-negative: 0;
    
    // box-shadow:	-5px 5px 5px #eee, 5px 5px 5px #eee;
}


.gl-calendar {
    flex-shrink: 0;
    position: relative;
}

.gl-calendar img {
    -ms-flex-negative: 0;
    max-width: 100%;
    overflow: auto;
    opacity: 0.6;
}

.gl-title {
    padding: 0 12px;
    margin-bottom: 20px;
    margin-top: -20px;
}

.gl-title img {
    text-align: center;
    margin: 40px 0 20px;
    height: auto;
    width: 100%;
    max-width: 100%;
}

.gl-body-block {
    position: absolute;
    width: 100%;
    top: 25%;
}

.gl-body {
    -ms-flex-negative: 0;
    overflow: auto;
}

.gl-form {
/*		background-color: #a7cfd5;*/
    background-color: #588CA9;
    /*border-radius: 10px;*/
    padding: 10px 20px;
    margin: 0 15px;
    color: #fff;
}

form, label, input, p, h1 {
    font-family: 'Lato', helvetica, arial, sans-serif;
}

label {display: block; font-weight: bold; margin-bottom: 5px;}

button {background-color: #3f6f8a; color: #fff; border-radius: 5px; border: 0; padding: 8px 15px;}

.form-input {
    display: flex;
}

.gl-copy {
    color: #666;
    font-size: 12px;
    padding: 40px 12px;
}

#mc_embed_signup{background:#f6f6f6; border-radius: 10px; clear:left; font:14px Helvetica,Arial,sans-serif; padding: 20px; }

#mc_embed_signup_scroll label {color: #333; font-size: 16px;}
#mc_embed_signup_scroll input[type=email] {width: 300px; max-width: 100%; padding: 10px}
#mc_embed_signup_scroll input[type=submit] {background-color: #3f6f8a; color: #fff; border-radius: 5px; border: 0; margin-top: 3px;
    font-size: 16px;}

/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */

.plain-container {
    .main {
        display: flex;
        align-items: center;
        justify-content: center;

        .card {
            border-radius: 10px;
            padding: 20px;
        }
    }



    .auth-content {
        max-width: 600px;

        .logo {
            max-width: 100%;
            margin-bottom: 20px;
        }

        button {
            margin-top: 1em;
        }
    }

    .container {
        min-height: auto;
        background-color: transparent;

        .modal-alternative {
            margin-top: 2em;
        }

        .logo {
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
}

.home-hero {
    padding: 20px 0;

    h1 {font-size: 20px; text-transform: uppercase; color: #e6e6e6; letter-spacing: 2px; margin-bottom: 1em}

    h2 {font-size: 56px;
        line-height: 1.2em;

        &.first {color: #BEF8FD; margin-bottom: 0;}
        &.second {color: #54D1DB}
    }

    .home-buttons {
        margin-top: 3em;
    }
}

.home-business-card {
    background-color: #F0F4F8;
    margin-top: 40px;

    .featured-site {font-size: 10px; letter-spacing: 2px; text-transform: uppercase; text-align: right; color: #334E68; margin-bottom: 5px;}

    h2 {font-size: 36px}
    p.place-name {text-transform: uppercase; color: #888; letter-spacing: 1px;}
    
    img {
        padding-bottom: 10px;
        width: 100%;
    }

    p {color: #333;}

    a {width: 100%;}
}

@media screen and (min-width: 768px) {
    .home-business-card {
        margin-top: 0;
    }
}

.alert {
    padding: 10px;

    &.alert-success {background-color: lightgreen}
}

.business-rows {
    margin-top: 40px;

    .business-row {
        background-color: #BCCCDC;
        padding: 10px;
        display: flex;

        .br-image {
            width: 125px;
            flex-shrink: 0;

            img { object-fit: cover; width: 125px; height: 125px; opacity: 1}
        }

        .br-image-logo {
            img {object-fit: contain; }
        }

        .br-main {flex-shrink: 1; margin: 0 20px}

        .br-name {text-transform: uppercase; font-weight: bold; font-size: 20px; display: block}
        .br-place-name {
            text-transform: uppercase; letter-spacing: 1px; font-size: 16px;  display: block;
        
            span {
                color: #eee;
            }
        }

        .br-link {            width: 100px;
            margin-left: auto;
            flex-shrink: 0;
}

    &:nth-child(2n) {
        background-color: #F0F4F8;

        p, .br-main {color: #333}

        .br-place-name {
            span {
                color: #888;
            }
        }

    }
}

}


.calendarBoxes {
    display: flex;
    padding: 0 15px;

    .calendarBox {
        width: 4%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #666;
        color: #e6e6e6;
    }

    .calendarBox-open {
        background-color: #9FB3C8;

        a {color: #fff;}
    }

    .calendarBox-today {
        background-color: #E668A7;
        
        a {
            color: #fff;
            font-weight: bold;
        }
    }

    //style={{fontSize:20}}
}

.day-text {
    font-size: 20px;
}

.carousel .slide {
    text-align: left !important;
    background-color: transparent !important;
    padding: 30px;
}

.listings-row {
    .business-rows {
        margin-top: 10px;
    }

    h2 {
        font-size: 28px;
        margin: 1em 0 0;
    }
}

.admin-nav {
    display: flex;
    list-style-type: none;
    padding: 0;

    li {
        margin-right: 10px;
    }
}